<template>
  <div>
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <!--begin::Card-->
            <div class="card card-custom gutter-b example example-compact">
              <div class="card-header">
                <h3 v-if="$route.meta.mode !== 'update'" class="card-title">
                  Ajouter un quartier
                </h3>
                <h3 v-if="$route.meta.mode === 'update'" class="card-title">
                  Modifier un quartier
                </h3>
              </div>
              <!--begin::Form-->
              <form class="form" @submit.prevent="save">
                <div class="card-body">
                  <div class="form-group row">
                    <div class="col-lg-6">
                      <label>Nom:</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.nom"
                        :class="{ 'is-invalid': errors.nom }"
                      />
                      <span class="form-text text-danger" v-if="errors.nom">{{
                        errors.nom[0]
                      }}</span>
                    </div>
                    <div class="col-lg-3">
                      <label>Populaire ?</label>
                      <div class="radio-inline">
                        <label class="radio radio-solid">
                          <input
                            type="radio"
                            name="beta"
                            v-model="form.populaire"
                            value="true"
                          />
                          <span></span>OUI</label
                        >
                        <label class="radio radio-solid">
                          <input
                            type="radio"
                            name="beta"
                            v-model="form.populaire"
                            value="false"
                          />
                          <span></span>NON</label
                        >
                        <span
                          class="form-text text-danger"
                          v-if="errors.populaire"
                          >{{ errors.populaire[0] }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-lg-6">
                      <label>Région:</label>
                      <multiselect
                        style="padding-top: 10px !important;"
                        v-model="form.region"
                        :options="option.regions"
                        :multiple="false"
                        :close-on-select="true"
                        :clear-on-select="true"
                        :hide-selected="true"
                        placeholder="Choisir une région"
                        label="nom"
                        track-by="id"
                        select-label=""
                        deselect-label=""
                        :class="{ 'is-invalid': errors.region }"
                      ></multiselect>
                      <span
                        class="form-text text-danger"
                        v-if="errors.region"
                        >{{ errors.region[0] }}</span
                      >
                    </div>
                    <div class="col-lg-6">
                      <label>Ville:</label>
                      <multiselect
                        style="padding-top: 10px !important;"
                        v-model="form.ville"
                        :options="option.villes"
                        :multiple="false"
                        :close-on-select="true"
                        :clear-on-select="true"
                        :hide-selected="true"
                        placeholder="Choisir la ville"
                        label="nom"
                        track-by="id"
                        select-label=""
                        deselect-label=""
                        @input="setVilleId()"
                        :class="{ 'is-invalid': errors.ville_id }"
                      ></multiselect>
                      <span
                        class="form-text text-danger"
                        v-if="errors.ville_id"
                        >{{ errors.ville_id[0] }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="row">
                    <div class="col-lg-12 text-right">
                      <button type="submit" class="btn btn-primary mr-2">
                        Enregistrer
                      </button>
                      <button type="reset" class="btn btn-secondary">
                        Annuler
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <!--end::Form-->
            </div>
            <!--end::Card-->
          </div>
        </div>
      </div>
      <!--end::Container-->
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";

export default {
  name: "Form",
  components: { Multiselect },
  data() {
    return {
      initialize: "district/create",
      store: "district",
      method: "post",
      form: {
        region: {
          id: "",
          villes: []
        }
      },
      option: {
        regions: [],
        villes: []
      },
      success: false,
      has_error: false,
      error: "",
      errors: {}
    };
  },
  beforeMount() {
    if (this.$route.meta.mode === "update") {
      this.initialize = "district/" + this.$route.params.id + "/edit";
      this.store = "district/" + this.$route.params.id;
      this.method = "put";
    }
    this.fetchData();
  },
  watch: {
    "form.region.id": "fetchDataVilles"
  },
  methods: {
    fetchData() {
      let vm = this;
      ApiService.get(this.initialize).then(function(response) {
        vm.form = response.data.form;
        vm.option = response.data.option;
      });
    },
    fetchDataVilles() {
      if (this.form.region.id !== undefined) {
        let vm = this;
        let laregion;
        laregion = vm.option.regions.filter(v => {
          return parseInt(vm.form.region.id) === parseInt(v.id);
        });
        vm.option.villes = laregion[0].villes;
        if (vm.form.ville.id !== "") {
          if (vm.form.region.id !== vm.form.ville.region_id) {
            vm.form.ville = "";
          }
        }
      }
    },
    setVilleId() {
      this.form.ville_id = this.form.ville.id;
    },
    save() {
      let vm = this;
      ApiService[this.method](this.store, this.form)
        .then(function(response) {
          if (response.data.success) {
            if (vm.$route.meta.mode === "create") {
              Swal.fire({
                title: "Réussie",
                text: "Quartier a été enrégistré.",
                icon: "success",
                showConfirmButton: false,
                timer: 2000,
                showClass: {
                  popup: "animate__animated animate__fadeInDown"
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp"
                }
              });
              vm.$router.push(vm.$route.meta.back);
            } else {
              Swal.fire({
                title: "Réussie",
                text: "Quartier a été modifié avec succès.",
                icon: "success",
                showConfirmButton: false,
                timer: 2000,
                showClass: {
                  popup: "animate__animated animate__fadeInDown"
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp"
                }
              });
              vm.$router.push(vm.$route.meta.back);
            }
            vm.$router.push(vm.$route.meta.back);
          }
        })
        .catch(function(error) {
          vm.errors = error.response.data.errors;
        });
    }
  }
};
</script>

<style scoped></style>
